import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useDispatch } from 'react-redux';
import User from "../models/userModel";
import { login } from '../features/user';
import url from "../data/url.json";
import "../styles/register.css";
import "../styles/global.css";
import environmentVariables from '../config/environmentVariables';
import HCaptcha from "@hcaptcha/react-hcaptcha";

// Add CSS rule to allow smooth scrolling to the top of the page
document.body.style.scrollBehavior = "smooth";

// Rules for entries input
const USER_REGEX = /^[A-z][A-z0-9-_]{3,33}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const PHONE_REGEX = /^[0-9]{10}$/;

const REGISTER_URL = `${environmentVariables.app.baseUrl}:${environmentVariables.app.backPort}/users/addUser`;
const VERIFY_CODE_URL = `${environmentVariables.app.baseUrl}:${environmentVariables.app.backPort}/users/verifyCodeRegister`;
const LOGINPAGE_URL = `${environmentVariables.app.baseUrl}:${environmentVariables.app.frontPort}/login`;

//const SITE_KEY = "ES_de7dd7f6b01e424babe65ad1228540c1";
const SITE_KEY = "e8ccd5f6-2790-44ba-a8c0-a83cb646e0b4";

const Register = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState('');
    const [validName, setValidName] = useState(false);
    const [userFocus, setUserFocus] = useState(false);
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const [phone, setPhone] = useState('');
    const [validPhone, setValidPhone] = useState(false);
    const [phoneFocus, setPhoneFocus] = useState(false);

    const dispatch = useDispatch();

    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [verifCode, setVerifCode] = useState('');
    const [validVerifCode, setValidVerifCode] = useState(false);
    const [verifCodeFocus, setVerifCodeFocus] = useState(false);

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [users, setUsers] = useState<User[]>([]);
    const [phoneVerificationRequired, setPhoneVerificationRequired] = useState(false);
    const [maskedPhone, setMaskedPhone] = useState('');

    const [captchaToken, setCaptchaToken] = useState('');

    useEffect(() => {
        fetchUsers();
    }, []);

    useEffect(() => {
        setValidName(USER_REGEX.test(user));
    }, [user]);

    useEffect(() => {
        setValidPhone(PHONE_REGEX.test(phone));
    }, [phone]);

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(pwd));
        setValidMatch(pwd === matchPwd);
    }, [pwd, matchPwd]);

    useEffect(() => {
        setValidVerifCode(verifCode.length === 6);
    }, [verifCode]);

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd, phone, matchPwd, verifCode]);

    const fetchUsers = async () => {
        try {
            console.log('Tentative de récupération des utilisateurs depuis le serveur...');
            const response = await axios.get(`${environmentVariables.app.baseUrl}:${environmentVariables.app.backPort}/users/users`, {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            });
            if (!response.data) {
                throw new Error(`Erreur HTTP ! statut: ${response.status}`);
            }
            const data = response.data;

            setUsers(data);
        } catch (error) {
            console.error("Erreur lors de la récupération des utilisateurs", error);
        }
    };

    const handleInitialSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();

        if (!captchaToken) {
            setErrMsg("Veuillez vérifier que vous n'êtes pas un robot.");
            return;
        }

        const v1 = USER_REGEX.test(user);
        const v2 = PWD_REGEX.test(pwd);
        const v3 = PHONE_REGEX.test(phone);
        if (!v1 || !v2 || !v3) {
            setErrMsg("Invalid Entry");
            return;
        }

        try {
            const response = await axios.post(REGISTER_URL,
                JSON.stringify({ user: user, phone: phone, captchaToken: captchaToken }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            if (response.data.phone) {
                setPhoneVerificationRequired(true);
                setMaskedPhone(response.data.phone);
                setVerifCode(response.data.code);
            } else {
                setSuccess(true);
                // Clear form fields
                setUser('');
                setPhone('');
                setPwd('');
                setMatchPwd('');
                setCaptchaToken('');
            }
        } catch (error) {
            console.error("Erreur lors de l'envoi du nom", error);
            setErrMsg('Les informations saisies ne semblent pas valides. Veuillez corriger votre saisie.');
        }
    };

    const handleCodeVerificationSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();

        try {
            const response = await axios.post(VERIFY_CODE_URL,
                JSON.stringify({ user: user, pwd: pwd, phone: phone, code: verifCode }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );

            const accessToken = response?.data?.accessToken;
            dispatch(login({ user: user, accessToken: accessToken }));
            // Handle successful verification
            setSuccess(true);
            setUser('');
            setPhone('');
            setPwd('');
            setMatchPwd('');
            setVerifCode('');
            navigate(from, { replace: true });
        } catch (err) {
            setErrMsg('Le code de vérification est invalide. Veuillez réessayer.');
        }
    };

    const onCaptchaChange = (token: string) => {
        setCaptchaToken(token);
    };

    return (
        <>
            <section className="mainBox">
                <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                <form onSubmit={phoneVerificationRequired ? handleCodeVerificationSubmit : handleInitialSubmit}>
                    <h1 className="mainBox__title">Bienvenue sur la plate-forme Stand’hop Pro</h1>
                    <label htmlFor="username">
                        <FontAwesomeIcon icon={faCheck} className={validName ? "mainBox__info--valid" : "mainBox__info--offscreen"} />
                        <FontAwesomeIcon icon={faTimes} className={validName || !user ? "mainBox__info--offscreen" : "mainBox__info--invalid"} />
                    </label>
                    <div className="mainBox__inputBox">
                        <input
                            type="text"
                            id="username"
                            autoComplete="off"
                            placeholder="Nom d'utilisateur"
                            onChange={(e) => setUser(e.target.value)}
                            value={user}
                            required
                            aria-invalid={validName ? "false" : "true"}
                            aria-describedby="uidnote"
                            onFocus={() => setUserFocus(true)}
                            onBlur={() => setUserFocus(false)}
                            disabled={phoneVerificationRequired}
                        />
                    </div>
                    <p id="uidnote" className={userFocus && user && !validName ? "mainBox__info--instructions" : "mainBox__info--offscreen"}>
                        <FontAwesomeIcon icon={faInfoCircle} />
                        4 to 24 characters.<br />
                        Must begin with a letter.<br />
                        Letters, numbers, underscores, hyphens allowed.
                    </p>

                    <label htmlFor="phone">
                        <FontAwesomeIcon icon={faCheck} className={validPhone ? "mainBox__info--valid" : "mainBox__info--offscreen"} />
                        <FontAwesomeIcon icon={faTimes} className={validPhone || !phone ? "mainBox__info--offscreen" : "mainBox__info--invalid"} />
                    </label>

                    <div className="mainBox__inputBox">
                        <input
                            type="text"
                            id="phone"
                            autoComplete="off"
                            placeholder="Numéro de téléphone"
                            onChange={(e) => setPhone(e.target.value)}
                            value={phone}
                            required
                            aria-invalid={validPhone ? "false" : "true"}
                            aria-describedby="phonenote"
                            onFocus={() => setPhoneFocus(true)}
                            onBlur={() => setPhoneFocus(false)}
                            disabled={phoneVerificationRequired}
                        />
                    </div>
                    <p id="phonenote" className={userFocus && user && !validName ? "mainBox__info--instructions" : "mainBox__info--offscreen"}>
                        <FontAwesomeIcon icon={faInfoCircle} />
                        Telephone sous le format 06.
                    </p>

                    <label htmlFor="password">
                        <FontAwesomeIcon icon={faCheck} className={validPwd ? "mainBox__info--valid" : "mainBox__info--offscreen"} />
                        <FontAwesomeIcon icon={faTimes} className={validPwd || !pwd ? "mainBox__info--offscreen" : "mainBox__info--invalid"} />
                    </label>
                    <div className="mainBox__inputBox">
                        <input
                            type="password"
                            id="password"
                            onChange={(e) => setPwd(e.target.value)}
                            placeholder="Mot de passe"
                            value={pwd}
                            required
                            aria-invalid={validPwd ? "false" : "true"}
                            aria-describedby="pwdnote"
                            onFocus={() => setPwdFocus(true)}
                            onBlur={() => setPwdFocus(false)}
                            disabled={phoneVerificationRequired}
                        />
                    </div>
                    <p id="pwdnote" className={pwdFocus && pwd && !validPwd ? "mainBox__info--instructions" : "mainBox__info--offscreen"}>
                        <FontAwesomeIcon icon={faInfoCircle} />
                        8 to 24 characters.<br />
                        Must include uppercase and lowercase letters, a number and a special character.<br />
                        Allowed special characters:
                        <div className="carList">
                            <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                        </div>
                    </p>

                    <label htmlFor="confirm_pwd">
                        <FontAwesomeIcon icon={faCheck} className={validMatch && matchPwd ? "mainBox__info--valid" : "mainBox__info--offscreen"} />
                        <FontAwesomeIcon icon={faTimes} className={validMatch || !matchPwd ? "mainBox__info--offscreen" : "mainBox__info--invalid"} />
                    </label>
                    <div className="mainBox__inputBox">
                        <input
                            type="password"
                            id="confirm_pwd"
                            onChange={(e) => setMatchPwd(e.target.value)}
                            placeholder="Confirmation du mot de passe"
                            value={matchPwd}
                            required
                            aria-invalid={validMatch ? "false" : "true"}
                            aria-describedby="confirmnote"
                            onFocus={() => setMatchFocus(true)}
                            onBlur={() => setMatchFocus(false)}
                            disabled={phoneVerificationRequired}
                        />
                    </div>
                    <p id="confirmnote" className={matchFocus && !validMatch ? "mainBox__info--instructions" : "mainBox__info--offscreen"}>
                        <FontAwesomeIcon icon={faInfoCircle} />
                        Must match the first password input field.
                    </p>

                    {phoneVerificationRequired && (
                        <div>
                            <p>Un code de vérification a été envoyé au numéro suivant : {maskedPhone}</p>
                            <label htmlFor="verifCode">
                                <FontAwesomeIcon icon={faCheck} className={validVerifCode ? "mainBox__info--valid" : "mainBox__info--offscreen"} />
                                <FontAwesomeIcon icon={faTimes} className={validVerifCode || !verifCode ? "mainBox__info--offscreen" : "mainBox__info--invalid"} />
                            </label>
                            <div className="mainBox__inputBox">
                                <input
                                    type="text"
                                    id="verifCode"
                                    placeholder='Code de vérification'
                                    onChange={(e) => setVerifCode(e.target.value)}
                                    value={verifCode}
                                    required
                                    aria-invalid={validVerifCode ? "false" : "true"}
                                    aria-describedby="verifcodenote"
                                    onFocus={() => setVerifCodeFocus(true)}
                                    onBlur={() => setVerifCodeFocus(false)}
                                />
                                <img src='/images/phone_icon.svg' className='mainBox__inputBox__icon' />
                            </div>
                        </div>
                    )}

                    <div className="captcha-container">
                        <HCaptcha
                            sitekey={environmentVariables.hCaptcha.siteKey}
                            onVerify={onCaptchaChange}
                        />
                    </div>

                    <button type="submit" className={ !validName || !validPwd || !validMatch || (phoneVerificationRequired && !validVerifCode) || !captchaToken ? 'mainBox__btn mainBox__btn--disable' : "mainBox__btn"} disabled={ !validName || !validPwd || !validMatch || (phoneVerificationRequired && !validVerifCode) || !captchaToken ? true : false}>{phoneVerificationRequired ? 'VÉRIFICATION DU CODE' : 'S\'INSCRIRE'}</button>
                </form>
                <ul>
                    {users.map(user => (
                        <li key={user.user}>{user.user}</li>
                    ))}
                </ul>
                <div className="mainBox__register">
                    <a href={LOGINPAGE_URL}>Se connecter</a>
                </div>
            </section>
        </>
    );
};

export default Register;
