import React, { useState, useEffect } from 'react';
import Patient from '../models/patientModel';
import { useSelector } from 'react-redux';
import { RootState } from '..';
import axios from 'axios';
import environmentVariables from '../config/environmentVariables';

const PatientForm: React.FC = () => {

  const user = useSelector((state: RootState) => state.user.userValue);

  const firstNameState = useState('');
  const surNameState = useState('');
  const birthdateState = useState('');
  const emailState = useState('');
  const [birthdateError, setBirthdateError] = useState('');
  const [patients, setPatients] = useState<Patient[]>([]);
  const states = [firstNameState, surNameState, birthdateState, emailState];

  useEffect(() => {
    fetchPatients();
  }, []);

  const fetchPatients = async () => {
    try {
      const response = await fetch(`${environmentVariables.app.baseUrl}:${environmentVariables.app.backPort}/users/patients`, {
        headers: {
          'Authorization': `Bearer ${user.accessToken}`
        }
      });
      if (!response.ok) {
        throw new Error(`Erreur HTTP ! statut: ${response.status}`);
      }
      const data = await response.json();
      setPatients(data);
    } catch (error) {
      console.error("Erreur lors de la récupération des utilisateurs", error);
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    
    let valid = true;
    
    if (!birthdateState[0]) {
      setBirthdateError('La date de naissance est obligatoire.');
      valid = false;
    } else {
      setBirthdateError('');
    }

    if (!valid) return;

    try {
      const response = await fetch(`${environmentVariables.app.baseUrl}:${environmentVariables.app.backPort}/users/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.accessToken}`
        },
        body: JSON.stringify({
          firstName: firstNameState[0],
          name: surNameState[0],
          birthdate: birthdateState[0],
          email: emailState[0],
          user: user.user
        })
      });

      if (!response.ok) {
        throw new Error(`Erreur HTTP ! statut: ${response.status}`);
      }

      const data = await response.json();
      console.log('Utilisateur créé avec succès:', data);

      states.forEach(state => state[1](''));
      fetchPatients();
    } catch (error) {
      console.error("Erreur lors de l'envoi du formulaire", error);
    }
  };

  function createField(fieldName: string, type: string, state: [string, React.Dispatch<React.SetStateAction<string>>]) {
    return (
      <label>
        {fieldName}:
        <input type={type} value={state[0]} onChange={(e) => state[1](e.target.value)} />
      </label>
    );
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <label>
          Nom:
          <input type="text" value={surNameState[0]} onChange={(e) => surNameState[1](e.target.value)} />
        </label>
        {createField("Prénom", "text", firstNameState)}
        <label>
          Date de naissance:
          <input type="date" value={birthdateState[0]} onChange={(e) => birthdateState[1](e.target.value)} />
        </label>
        {createField("Email", "email", emailState)}
        {birthdateError && <p style={{ color: 'red' }}>{birthdateError}</p>}
        <button type="submit">Submit</button>
      </form>

      <h2>Liste des Patients</h2>
      <ul>
        {patients.map(patient => (
          <li key={patient.id}>{patient.name} {patient.firstName} {patient.birthdate} {patient.email}</li>
        ))}
      </ul>
    </div>
  );
};

export default PatientForm;
