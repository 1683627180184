
const environmentVariables = {
    app: {
      secretKey: process.env.SECRET_KEY,
      baseUrl: process.env.BASE_URL || 'https://pro.standhop.com',
      backPort: process.env.BACK_PORT || '8444',
      frontPort: process.env.FRONT_PORT || '443'
    },
    hCaptcha: {
      siteKey: process.env.HCAPTCHA_SITE_KEY || 'e8ccd5f6-2790-44ba-a8c0-a83cb646e0b4'
    }
};
  
export default environmentVariables;