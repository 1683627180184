import React from 'react'
import Banner from '../components/Banner'
import DisplayPFPPDetail from '../components/DisplayPFPPDetails'

function DisplayPFPPDetailsPage() {
  return (
    <body>
        <Banner />
        <div className='displayContainer'> <DisplayPFPPDetail/> </div>
    </body>
  )
}

export default DisplayPFPPDetailsPage
