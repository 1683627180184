/** 
 * @module ForgotPassword
 * Forgot password main component, display an email form which authentify the user in database and send an reset password link by email
 * @returns {any}
 */
import { useState, useEffect } from 'react';
import axios from "axios"
import { login } from '../features/user';
import "../styles/global.css"
import environmentVariables from '../config/environmentVariables';

const FORGOT_URL = `${environmentVariables.app.baseUrl}:${environmentVariables.app.backPort}/users/forgotPassword`;

/** 
 * @function ForgotPassword
 * Main function of the module
 * @returns 
 */
const ForgotPassword = () => {

    const [email, setEmail] = useState("");
    const [errMsg, setErrMsg] = useState("");
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        setErrMsg('');
    }, [email]);

    /** 
     * @function handleSubmit
     * Email submit action, send request to back end to compare values to the user database and send an email if a matched one was found
     */
    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();

        try {
            const response = await axios.post(FORGOT_URL,
                JSON.stringify({ email: email }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            const success = response?.data?.isSuccess;
            if (success) {
                setErrMsg('Success');
            } else {
                setErrMsg('Link sending Failed');
            }
            setEmail('');
            const succ: boolean = response?.data?.isSuccess
            setSuccess(succ)
        } catch (err) {
            console.log(err)
            setErrMsg('Link sending Failed, error');
        }
    }


    //HTML form
    return (
        <>
            <section className='mainBox'>
                <p className={errMsg ? "mainBox__errmsg" : "mainBox__offscreen"} aria-live="assertive">{errMsg}</p>
                {success ? (
                    <>
                        <p>Reset password link sent to your email!</p>
                    </>
                ) : (
                    <form onSubmit={handleSubmit}>
                        <h1 className='mainBox__title'>Forgot Password</h1>
                        <label htmlFor="forgot"></label>
                        <div className='mainBox__inputBox'>
                            <input
                                type="text"
                                id="email"
                                autoComplete="off"
                                placeholder='Email'
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                required
                            />
                        </div>

                        <button type="submit" className='mainBox__btn'>Send</button>

                    </form>

                )}

            </section>
        </>
    )
}


export default ForgotPassword